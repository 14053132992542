import EButton from '@components/button/index.vue';
import ETable from '@components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import Dialog from '@components/dialog/dialog.vue';
import NoData from '@components/noData/index.vue';

import { addSemester, getYearList, editSemester, deleteSemester, getCurrentYear, editYear } from '@/api/basic';

import Vue from 'vue';
import { DatePicker, Form, FormItem, Input } from 'element-ui';
import cloneDeep from 'lodash.clonedeep';
import { formatDateTime } from '@/utils/common';
import basicHeader from '@components/backStageHeader/index.vue';

export default {
  name: 'Semester',

  components: {
    basicHeader,
    Dialog,
    ETable,
    EButton,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    NoData,
  },

  data() {
    const validateDate = (rule, value, callback) => {
      if (!value[0]) {
        callback(new Error('请选择起止时间'));
        return;
      }

      callback();
    };

    return {
      loading: false,

      currentYear: {},

      tableList: [
        {
          data: [],
        },
      ],

      semesterModal: {
        visible: false,
        title: '新增学期',
        yearStart: '', // 学年开始
        yearEnd: '', // 学年开始
        rules: {
          schoolYearName: [{ required: true }],
          termName: [
            { required: true, message: '请输入学期名称', trigger: ['blur', 'change'] },
            { max: 20, message: '20字符，支持汉字/字母/数字/特殊字符', trigger: ['blur', 'change'] },
          ],
          date: [
            { required: true, message: '请选择起止时间', trigger: ['blur', 'change'] },
            { validator: validateDate, trigger: ['blur', 'change'] },
          ],
        },
        form: {
          date: '',
        },
      },

      yearModal: {
        visible: false,
        rules: {
          schoolYearName: [
            { required: true, message: '请输入学年名称', trigger: ['blur', 'change'] },
            { max: 20, message: '20字符，支持汉字/字母/数字/特殊字符', trigger: ['blur', 'change'] },
          ],
        },
        form: {},
      },

      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },

      defaultTime: ['00:00:00', '23:59:59'],
    };
  },

  filters: {
    formatDate(date) {
      return formatDateTime(date, 'yyyy年MM月dd日 HH:mm:ss');
    },
  },

  methods: {
    handleFocus() {
      const pannel = document.querySelector('.el-picker-panel__body');

      if (pannel && !pannel.onclick) {
        pannel.onclick = (e) => {
          let classDom;

          if (e.target.nodeName === 'SPAN') {
            classDom = e.target.parentNode.parentNode;
          } else if (e.target.nodeName === 'DIV') {
            classDom = e.target.parentNode;
          }

          if (!classDom) {
            return;
          }

          const prevMonthIcon = document.querySelector('.el-date-range-picker__header .el-icon-arrow-left');
          const nextMonthIcon = document.querySelector('.el-date-range-picker__header .el-icon-arrow-right');

          if (classDom.className === 'prev-month') {
            prevMonthIcon.click();
          } else if (classDom.className === 'next-month') {
            nextMonthIcon.click();
          }
        };
      }
    },

    handleBlur() {
      const pannel = document.querySelector('.el-picker-panel__body');
      pannel.onclick = null;
    },

    clearValid() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },

    editYear() {
      this.loading = true;

      this.$refs.yearForm.validate((valid) => {
        if (valid) {
          const { schoolYearId, schoolYearName } = this.yearModal.form;

          editYear({
            schoolYearId,
            schoolYearName,
          })
            .then((res) => {
              if (res.status === 0) {
                this.$message.success('编辑成功！');
                this.yearModal.visible = false;
                this.getTableData();
              }
            })
            .catch(() => (this.loading = false));
        } else {
          this.loading = false;
        }
      });
    },

    showYearDialog(table) {
      this.yearModal.visible = true;
      this.yearModal.form = cloneDeep(table);
      this.loading = false;
    },

    showSemesterDialog(row) {
      const { termId, startTime, endTime, schoolYearId } = row;
      const table = this.tableList.find((item) => item.schoolYearId === schoolYearId);
      const yearStart = table.startTime;
      const yearEnd = table.endTime;

      let lastSemesterEndTime;

      // 编辑
      if (termId) {
        const curIndex = table.basicSchTermDTOList.findIndex((item) => item.termId === row.termId);
        lastSemesterEndTime = table.basicSchTermDTOList[curIndex + 1]?.endTime;
      }
      // 新增
      else {
        lastSemesterEndTime = table.basicSchTermDTOList[0]?.endTime;
      }

      let dateStartFromLast = ['', ''];

      if (lastSemesterEndTime) {
        let lastSemesterEndDate = new Date(lastSemesterEndTime);
        lastSemesterEndDate = new Date(lastSemesterEndDate.setSeconds(lastSemesterEndDate.getSeconds() + 1));
        dateStartFromLast[0] = formatDateTime(lastSemesterEndDate, 'yyyy-MM-dd HH:mm:ss');
        dateStartFromLast[1] = formatDateTime(lastSemesterEndDate, 'yyyy-MM-dd') + ' 23:59:59';
        this.defaultTime = [formatDateTime(lastSemesterEndDate, 'HH:mm:ss'), '23:59:59'];
      } else {
        this.defaultTime = [formatDateTime(yearStart, 'HH:mm:ss'), '23:59:59'];
      }

      this.semesterModal.form = {};
      this.semesterModal = {
        ...this.semesterModal,

        visible: true,
        title: termId ? '编辑学期' : '新增学期',
        yearStart,
        yearEnd,
        form: {
          ...cloneDeep(row),

          date: termId ? [startTime, endTime] : dateStartFromLast,
        },
      };
      this.loading = false;

      this.clearValid();
    },

    handleSave() {
      this.loading = true;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { schoolYearId, termName, date, termId } = this.semesterModal.form;

          let res;
          if (termId) {
            res = await editSemester({
              termId,
              termName,
              startTime: date[0],
              endTime: date[1],
            }).catch(() => (this.loading = false));
          } else {
            res = await addSemester({
              schoolYearId,
              termName,
              startTime: date[0],
              endTime: date[1],
            }).catch(() => (this.loading = false));
          }

          if (res.status === 0) {
            this.$message.success(termId ? '编辑成功！' : '新增成功！');
            this.semesterModal.visible = false;
            this.getTableData();
          }
        } else {
          this.loading = false;
        }
      });
    },

    pageChange() {
      this.getTableData();
    },

    async getTableData() {
      const res = await getYearList();

      if (res.status === 0) {
        this.tableList = res.result;
      }
    },

    async handleDelete(row) {
      const { termId, termName, schoolYearName } = row;

      this.$msgbox
        .confirm(`确定要删除【${schoolYearName} ${termName}】吗？`, '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'btn-ghost-danger',
        })
        .then(() => {
          deleteSemester({
            termId,
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功！');
              this.getTableData();
            }
          });
        });
    },

    async getCurrentYear() {
      const res = await getCurrentYear();

      if (res.status === 0) {
        this.currentYear = res.result;
      }
    },
  },

  mounted() {
    this.getTableData();
    this.getCurrentYear();
  },

  computed: {
    pickerOptions() {
      return {
        disabledDate: (date) => {
          const { yearStart, yearEnd } = this.semesterModal;
          let StartDisable;
          let endDisable;

          if (yearStart) {
            // 获取00:00:00日期
            let startDateTime = new Date(new Date(yearStart).toLocaleDateString());
            startDateTime = formatDateTime(startDateTime, 'yyyy-MM-dd HH:mm:ss');

            StartDisable = date.getTime() < new Date(startDateTime).getTime();
          }

          if (yearEnd) {
            // 获取23:59:59日期
            let endDateTime = formatDateTime(yearEnd, 'yyyy-MM-dd');
            endDateTime = `${endDateTime} 23:59:59`;

            endDisable = date.getTime() > new Date(endDateTime).getTime();
          }

          return StartDisable || endDisable || false;
        },

        shortcuts: [
          {
            text: '取消',
            onClick: () => {
              this.$refs['date-time-pick'].hidePicker();
            },
          },
        ],
      };
    },

    columns() {
      return [
        {
          label: '学期名称',
          prop: 'termName',
          component: Vue.extend({
            props: ['row', 'col'],
            render() {
              return (
                <p>
                  <span>{this.row.termName}</span>
                  {this.row.isCurrentTerm === 0 && <span class="text-theme ml-16px">当前学期</span>}
                </p>
              );
            },
          }),
        },
        {
          label: '起止时间',
          prop: 'date',
          formatter: (row) =>
            `${formatDateTime(row.startTime, 'yyyy年MM月dd日')} - ${formatDateTime(row.endTime, 'yyyy年MM月dd日')}`,
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: (row) => this.disableHandle(row, 'edit'),
                handle: this.showSemesterDialog,
              },
              {
                text: '删除',
                disabled: (row) => this.disableHandle(row, 'delete'),
                handle: this.handleDelete,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },

    disableHandle() {
      return (row, handle) => {
        const year = this.tableList.find((item) => item.schoolYearId === row.schoolYearId);
        const isOld = row.endTime ? new Date() > new Date(row.endTime) : false; // 历史学期
        const isCurrent = row.isCurrentTerm === 0; // 当前学期
        const isFresh = new Date(year.startTime) > new Date(); // 未开始学期

        if (isOld) return ['edit', 'delete'].includes(handle);
        else if (isCurrent) return ['delete'].includes(handle);
        else if (isFresh) return [].includes(handle);
      };
    },

    isCurrentYear() {
      return (table) => this.currentYear.schoolYearId === table.schoolYearId;
    },
  },
};
